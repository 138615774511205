<script>
import api from "@/api";
import Loader from "vue-element-loading";

export default {
  name: "ManagerSettings",
  components: {
    Loader,
  },
  data: function () {
    return {
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      selectedClosedDow: "",
      loading: false,
      logos: [],
      homepage_images: [],
      logoFormData: null,
      homepageFormData: null,
      space: null,
    };
  },
  methods: {
    async getSpace() {
      this.loading = true;
      try {
        await this.$store.dispatch("Manager/getSpaces");
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
      this.$store.getters["General/getSpaceBySlug"](this.slug);
    },
    removeIndex(index) {
      console.log(index);
      const cdow = this.closedDaysOfWeek;
      cdow.splice(index, 1);
      this.space.closed_days_of_week = cdow.join(",");
    },
    save() {
      this.loading = true;
      var spaceId = this.spaceId;
      const url = "/SpaceLocation/" + spaceId + "/";
      api.Manager.axios
        .patch(url, this.space)
        .then(() => {
          alert("Saved.");
          this.loading = false;
          this.getSpace();
        })
        .catch((error) => {
          console.error(error);
          var t = error?.response?.text;
          if (!t) {
            t = error?.response?.status;
          }
          alert("Error updating: " + t);
        });
    },
    addHomePageImage(event) {
      var parent_ = this;
      parent_.homepage_images = [];
      var fileList = event.target.files;
      this.homepageFormData = new FormData();
      if (fileList.length) {
        this.homepageFormData.append(
          "homeimage" + 0,
          fileList[0],
          fileList[0].name
        );
        var reader = new FileReader();
        reader.onload = function (e) {
          parent_.homepage_images.push(e.target.result);
        };
        reader.readAsDataURL(fileList[0]);
      }
    },
    addLogo(event) {
      var parent_ = this;
      parent_.logos = [];
      var fileList = event.target.files;
      this.logoFormData = new FormData();
      for (var i = 0; i < fileList.length; i++) {
        this.logoFormData.append("image" + i, fileList[i], fileList[i].name);
        var reader = new FileReader();
        reader.onload = function (e) {
          parent_.logos.push(e.target.result);
        };
        reader.readAsDataURL(fileList[i]);
      }
    },
    uploadHomeImage() {
      var url = "/setHomePageImage/" + this.spaceId;
      api.Manager.axios.post(url, this.homepageFormData).then(() => {
        alert("Uploaded");
        this.homepage_images = [];
        this.homepageFormData = null;
        this.getSpace();
      });
    },
    uploadLogos() {
      var url = "/setLogo/" + this.spaceId;
      api.Manager.axios.post(url, this.logoFormData).then(() => {
        alert("Uploaded");
        this.logos = [];
        this.logoFormData = null;
        this.getSpace();
      });
    },
  },
  computed: {
    isOpen24Hour: {
      set(is24Hour) {
        if (is24Hour) {
          this.space.start_hour = this.space.end_hour = -1;
        } else {
          this.space.start_hour = 6;
          this.space.end_hour = 23;
        }
      },
      get() {
        if (this.space.start_hour <= -1 && this.space.end_hour <= -1) {
          return true;
        }
        return false;
      },
    },
    slug() {
      return this.$route.params.slug;
    },
    spaceId() {
      return this.space.id;
    },
  },
  created() {
    this.space = this.$store.getters["General/getSpaceBySlug"](this.slug);
  },
};
</script>

<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col col-md-8 px-4" id="ManagerSettings">
        <Loader :active="loading" />
        <div class="row justify-content-center">
          <div class="col-12 px-1 inner_form" v-if="space != null">
            <!-- for space -->

            <div class="row" id="image_row">
              <div class="col-12 col-sm-6">
                <div class="row">
                  <div class="col-12 col-sm-5">Logo</div>

                  <div class="col">
                    <div class="row justify-content-center">
                      <div class="col">
                        <template v-if="space.logo != null && space.logo != ''">
                          <img
                            id="logo"
                            class="u-image img-fluid"
                            :src="'/media/' + space.logo.pathname"
                            alt=""
                          />
                        </template>

                        <input
                          class=""
                          type="file"
                          name="pic_file"
                          value="Add file."
                          @change="addLogo($event)"
                          multiple
                        />
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <div class="col-12">
                        <button
                          id="uploadbtn"
                          class="btn btn-danger"
                          @click.prevent="uploadLogos"
                        >
                          Set Logo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col d-sm-none">
                <div class="row">
                  <div class="col">
                    <hr />
                  </div>
                </div>
              </div>

              <div class="col-12 col-sm-6">
                <div class="row">
                  <div class="col-12 col-sm-5">HomePage Landing Image</div>

                  <div class="col">
                    <div class="row justify-content-center">
                      <div class="col">
                        <template
                          v-if="
                            space.homepage_image != null &&
                            space.homepage_image != ''
                          "
                        >
                          <img
                            id="logo"
                            class="u-image img-thumbnail"
                            :src="'/media/' + space.homepage_image.pathname"
                            alt=""
                          />
                        </template>
                        <input
                          class=""
                          type="file"
                          name="pic_file"
                          value="Add file."
                          @change="addHomePageImage($event)"
                          multiple
                        />
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-12">
                        <button
                          id="uploadbtn"
                          class="btn btn-danger"
                          @click.prevent="uploadHomeImage"
                        >
                          Set HomePage Image(300px x 250px)
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 px-1 inner_form" v-if="space != null">
            <form class="form form-horizontal" @submit.prevent="save">
              <div class="row">
                <div class="col-6 col-sm-6 col-lg-2">Space Name</div>
                <div class="col">
                  <input
                    class="form-control"
                    type="text"
                    v-model="space.name"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-sm-6 col-lg-2">Phone Number</div>
                <div class="col">
                  <input
                    class="form-control"
                    type="text"
                    v-model="space.phone_number"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-sm-6 col-lg-2">Website</div>
                <div class="col">
                  <input
                    class="form-control"
                    type="text"
                    v-model="space.website"
                  />
                </div>
              </div>

              <!-- <div class="row"> -->
              <!--     <div class="col-6 col-sm-6 col-lg-2"> -->
              <!--         Restricted Hours -->
              <!--     </div> -->
              <!--     <div class="col"> -->
              <!--         <input class="form-control" type="text" v-model="space.restricted_hours"> -->
              <!--     </div> -->
              <!-- </div> -->

              <div class="row">
                <div class="col-6 col-sm-6 col-lg-2">Location</div>
                <div class="col">
                  <textarea
                    class="form-control"
                    type="text"
                    rows="5"
                    v-model="space.location"
                  ></textarea>
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-sm-6 col-lg-2">
                  Plus Code
                  <br />
                  <a href="https://maps.google.com/pluscodes/" target="_blank"
                    >What is a plus code?</a
                  >
                </div>
                <div class="col">
                  <textarea
                    class="form-control"
                    type="text"
                    rows="5"
                    v-model="space.plus_code"
                  ></textarea>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="row">
                    <div class="col-6 col-sm-6">
                      Cancel at least x hours before:
                    </div>
                    <div class="col">
                      <input
                        class="form-control"
                        type="number"
                        v-model="space.minimum_cancel_hours"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="row">
                    <div class="col-6 col-sm-4">Auto Confirm New Bookings?</div>
                    <div class="col">
                      <input
                        type="checkbox"
                        v-model="space.auto_approve_new_booking"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="row">
                    <div class="col-6 col-sm-4">Allow Half Hour Bookings?</div>
                    <div class="col">
                      <input
                        type="checkbox"
                        v-model="space.allow_half_hour_bookings"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-sm-4 col-lg-2">Terms of Service</div>
                <div class="col">
                  <textarea
                    class="form-control"
                    v-model="space.tos"
                    rows="6"
                  ></textarea>
                </div>
              </div>

              <div class="row">
                <div class="col-6 col-sm-6 col-lg-2"></div>
                <div class="col">
                  <button class="btn btn-success" @click.prevent="save">
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div v-else>Loading data...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#image_row {
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#ManagerSettings {
  border-radius: 2px;
  .u-image {
    max-width: 100px;
    margin-bottom: 10px;
    max-height: 100px;
  }
  .inner_form {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: white;
    form > div {
      margin-bottom: 5px;
    }
  }
  #uploadbtn {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>
